<template>
  <div id="CartPage">
    <div class="section">
      <div class="container">
        <p class="title">{{ $t("cart.title") }}</p>
        <div v-if="cart.products && cart.products.length > 0">
          <!-- Cart Items -->
          <div v-for="(cartItem, index) in cart.products" :key="index">
            <div class="columns is-centered">
              <div class="column is-2">
                <figure class="image is-128x128">
                  <img :src="cartItem.main_image" />
                </figure>
              </div>
              <div class="column is-4" style="text-align: left;">
                <p class="title is-4 cell-item">{{ cartItem.name }}</p>
                <p class="subtitle is-6">
                  {{ $t("label.price") }}:
                  {{ cartItem.price | thousandseparators }}
                  {{ $t("label.currency_unit") }}
                </p>
                <a style="color: #ff3860;" @click="removeCartItem(cartItem)">
                  {{ $t("btn.remove") }}
                </a>
              </div>
              <div class="column">
                <b-field class="cell-item">
                  <b-numberinput
                    min="1"
                    type="is-light"
                    v-model.lazy="cartItem.quantity"
                    @input="quantityChange(cartItem)"
                  />
                </b-field>
              </div>
              <div class="column is-offset-1" style="text-align: right;">
                {{ $t("label.total_price") }}:
                <p class="title is-4 cell-item">
                  {{
                    (cartItem.price * cartItem.quantity) | thousandseparators
                  }}
                  {{ $t("label.currency_unit") }}
                </p>
              </div>
            </div>
            <hr />
          </div>

          <!-- Cart Sub Total -->
          <div class="columns">
            <div class="column" style="text-align: right;">
              {{ $t("label.sub_total") }}:
              <p>
                <span class="title">
                  {{ subTotal | thousandseparators }}
                  {{ $t("label.currency_unit") }}
                </span>
              </p>
              <p style="color: #7a7a7a">{{ $t("cart.remark") }}</p>
              <b-button
                type="is-primary"
                :disabled="isDisableCheckout"
                @click="requestValidateCart()"
              >
                {{ $t("btn.check_out") }}
              </b-button>
            </div>
          </div>
        </div>
        <div v-else>
          <section class="hero is-medium">
            <div class="hero-body">
              <div class="container">
                <h2 class="title">
                  <b-icon icon="cart-remove" size="is-large" type="is-danger" />
                </h2>
                <h2 class="subtitle">
                  {{ $t("cart.no_items") }}
                </h2>
                <b-button
                  type="is-primary"
                  @click="navigateToCategoryCollection"
                >
                  {{ $t("btn.continue_shoping") }}
                </b-button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Helper from "@/utils/helper";
import { mapState, mapMutations } from "vuex";
import CartApiManager from "@/api/CartApiManager";

export default {
  name: "CartPage",
  watch: {
    current_lang: function(val) {
      if (val) {
        this.getUpdatedCart(this.cart._id);
      }
    }
  },
  data() {
    return {
      isDisableCheckout: false
    };
  },
  computed: {
    ...mapState(["cart", "current_lang"]),
    subTotal: function() {
      var sum = this.cart.products.reduce(function(prev, cur) {
        return prev + cur.price * cur.quantity;
      }, 0);
      return sum;
    }
  },
  filters: {
    thousandseparators: function(value) {
      return Helper.toThousandSeparatorsFormatter(value);
    }
  },
  methods: {
    ...mapMutations({
      setCart: "SET_CART",
      removeCart: "REMOVE_CART"
    }),
    quantityChange: function(item) {
      let cartItem = {
        product_id: item.product_id,
        quantity: item.quantity
      };
      this.requestUpdatetoExistingCart(cartItem);
    },
    getUpdatedCart: async function(cart_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CartApiManager.requestGetCart(cart_id);
      switch (response.code) {
        case "0":
          this.setCart(response.data);
          break;

        default:
          this.removeCart();
          break;
      }
      loadingComponent.close();
    },
    requestUpdatetoExistingCart: async function(cartItem) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CartApiManager.requestUpdateItemToExistingCart(
        this.cart._id,
        cartItem
      );
      switch (response.code) {
        case "0":
          this.isDisableCheckout = false;
          this.setCart(response.data);
          break;

        default:
          this.isDisableCheckout = true;
          this.$buefy.snackbar.open({
            message: "Error - " + response.message,
            type: "is-danger",
            actionText: "OK",
            queue: true
          });
          break;
      }
      loadingComponent.close();
    },
    removeCartItem: async function(item) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const cartItem = {
        product_id: item.product_id,
        quantity: 0
      };
      const response = await CartApiManager.requestUpdateItemToExistingCart(
        this.cart._id,
        cartItem
      );
      switch (response.code) {
        case "0":
          this.setCart(response.data);
          break;

        default:
          break;
      }
      loadingComponent.close();
    },
    requestValidateCart: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CartApiManager.requestValidateCart(this.cart._id);
      loadingComponent.close();

      switch (response.code) {
        case "0":
          this.navigateToCheckout();
          break;

        default:
          break;
      }
    },
    navigateToCheckout: function() {
      this.$router.push({
        name: "checkout"
      });
    },
    navigateToCategoryCollection: function() {
      this.$router.push({
        name: "collection"
      });
    }
  },
  mounted() {
    if (this.cart._id) {
      this.getUpdatedCart(this.cart._id);
    }
  }
};
</script>

<style scoped>
.cell-item {
  padding-top: 1rem;
}
</style>
